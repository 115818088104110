<template>
    <Editor v-if="editorKey2" :api-key="editorKey2" :init="editorInit" v-model="editorContent" />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    components: {
        Editor
    },
    props: ['value'],
    data() {
        return {
            editorKey: process.env.VUE_APP_EDITOR_KEY,
            editorKey2: null,
            editorContent: this.value,
            editorInit: {
                max_height: 320,
                resize: false,
                forced_root_block: 'p',
                newline_behavior: 'block',
                plugins: 'code preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                toolbar: 'code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
            }
        }
    },
    watch: {
        editorContent(newVal) {
            this.$emit('input', newVal);
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        getEditorText() {
            return this.$store.getters.getEditorText
        },
    },
    methods: {
        fetchData() {
            this.$store.dispatch('editorText')
                .then(() => {
                    this.editorKey2 = this.getEditorText.editorKey
                })
        },
    }
}
</script>