<template>
    <nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
            <div class="flex items-center justify-between">
                <div class="flex items-center justify-start rtl:justify-end">
                    <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar"
                        aria-controls="logo-sidebar" type="button"
                        class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        <span class="sr-only">Open sidebar</span>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd"
                                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
                            </path>
                        </svg>
                    </button>
                    <a @click="homeRole" href="" class="flex ms-2 md:me-24">
                        <img src="@/assets/smartbible_logo.png" class="h-8 me-3" alt="FlowBite Logo" />
                        <span
                            class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-black">SmartBible
                            (<span v-if="isAdminSubKomunitas">{{ dataSubKomunitas.namaSubKomunitas }}</span><span
                                v-if="isSuperAdmin || isAdmin1 || isAdmin">{{ dataAgensi.namaAgensi }}</span>)</span>
                    </a>
                </div>
                <div class="flex items-center">
                    <div class="flex items-center ms-3">
                        <div>
                            <button type="button"
                                class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                aria-expanded="false" data-dropdown-toggle="dropdown-user">
                                <span class="sr-only">Open user menu</span>
                                <img class="w-8 h-8 rounded-full" src="@/assets/smartbible_logo.png" alt="user photo">
                            </button>
                        </div>
                        <div class="z-40 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                            id="dropdown-user">
                            <template v-if="dataUser">
                                <div class="px-4 py-3" role="none">
                                    <p class="text-sm text-gray-900 dark:text-white" role="none">
                                        {{ dataUser.username }}
                                    </p>
                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                                        role="none">
                                        {{ dataUser.email }}
                                    </p>
                                </div>
                            </template>
                            <ul class="py-1" role="none">
                                <!-- <li>
                                    <a @click="refreshToken"
                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                        role="menuitem">Settings</a>
                                </li> -->
                                <li>
                                    <a href="#" @click="logout"
                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                        role="menuitem">Sign out</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <aside id="logo-sidebar"
        class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar">
        <div class="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
            <ul class="space-y-2 font-medium">
                <span>
                    <li>
                        <a @click="homeRole" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="ms-3">Home</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || isAdmin || dataUser.acl.acl_DASHBOARD">
                    <li :class="{ 'bg-gray-200 rounded-lg': ['dashboard'].includes(currentPage) }">
                        <a @click="dashboardSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                viewBox="0 0 22 21">
                                <path
                                    d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                <path
                                    d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                            </svg>
                            <span class="ms-3">Dashboard</span>
                        </a>
                    </li>
                </span>
                <span v-if="isAdminSubKomunitas">
                    <li :class="{ 'bg-gray-200 rounded-lg': ['dashboard-sub-komunitas'].includes(currentPage) }">
                        <a @click="dashboardSubKomunitas" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                viewBox="0 0 22 21">
                                <path
                                    d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                <path
                                    d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                            </svg>
                            <span class="ms-3">Dashboard</span>
                        </a>
                    </li>
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-reflection-sub-komunitas', 'list-reflection-search-sub-komunitas', 'create-reflection-sub-komunitas', 'list-trash-reflection-sub-komunitas', 'list-trash-reflection-search-sub-komunitas', 'edit-reflection-sub-komunitas'].includes(currentPage) }">
                        <a @click="reflectionSubKomunitas" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Reflection</span>
                        </a>
                    </li>
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-agenda-sub-komunitas', 'list-agenda-search-sub-komunitas', 'create-agenda-sub-komunitas', 'edit-agenda-sub-komunitas', 'list-copy-agenda-sub-komunitas'].includes(currentPage) }">
                        <a @click="agendaSubKomunitas" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Bible Plan</span>
                        </a>
                    </li>
                    <li :class="{ 'bg-gray-200 rounded-lg': ['create-broadcast-sub-komunitas'].includes(currentPage) }">
                        <a @click="broadcastSubKomunitas" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M18.458 3.11A1 1 0 0 1 19 4v16a1 1 0 0 1-1.581.814L12 16.944V7.056l5.419-3.87a1 1 0 0 1 1.039-.076ZM22 12c0 1.48-.804 2.773-2 3.465v-6.93c1.196.692 2 1.984 2 3.465ZM10 8H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6V8Zm0 9H5v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Broadcast</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_TOPIC">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-topic', 'list-topic=search'].includes(currentPage) }">
                        <a @click="topicSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                    d="M4 6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h11.613a2 2 0 0 0 1.346-.52l4.4-4a2 2 0 0 0 0-2.96l-4.4-4A2 2 0 0 0 15.613 6H4Z" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Topic</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_REFLECTION">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-reflection', 'list-reflection-search', 'create-reflection', 'list-trash-reflection', 'list-trash-reflection-search', 'edit-reflection'].includes(currentPage) }">
                        <a @click="reflectionSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Reflection</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_ARTICLE_AND_NEWS">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-article', 'list-article-search', 'create-article', 'list-trash-article', 'list-trash-article-search', 'edit-article'].includes(currentPage) }">
                        <a @click="articleSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Article and News</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_BIBLE_PLAN">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-agenda', 'list-agenda-search', 'create-agenda', 'list-trash-agenda', 'list-trash-agenda-search', 'edit-agenda'].includes(currentPage) }">
                        <a @click="agendaSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Bible Plan</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_PRAYER">
                    <li :class="{ 'bg-gray-200 rounded-lg': ['list-pokok-doa'].includes(currentPage) }">
                        <a @click="pokokDoaSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Prayer</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_VIDEO">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-playlist', 'list-playlist-search', 'list-trash-playlist', 'list-trash-playlist-search', 'list-video', 'list-search'].includes(currentPage) }">
                        <a @click="playlistSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm-2 4a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H9Zm0 2h2v2H9v-2Zm7.965-.557a1 1 0 0 0-1.692-.72l-1.268 1.218a1 1 0 0 0-.308.721v.733a1 1 0 0 0 .37.776l1.267 1.032a1 1 0 0 0 1.631-.776v-2.984Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Video</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_PRODUCT">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-produk', 'list-produk-search', 'create-produk', 'edit-thumbnail-produk', 'edit-produk'].includes(currentPage) }">
                        <a @click="produkSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                    d="M12.268 6A2 2 0 0 0 14 9h1v1a2 2 0 0 0 3.04 1.708l-.311 1.496a1 1 0 0 1-.979.796H8.605l.208 1H16a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L4.686 5H4a1 1 0 0 1 0-2h1.5a1 1 0 0 1 .979.796L6.939 6h5.329Z" />
                                <path
                                    d="M18 4a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0V8h2a1 1 0 1 0 0-2h-2V4Z" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Product</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_SLIDER">
                    <li :class="{ 'bg-gray-200 rounded-lg': ['list-slider'].includes(currentPage) }">
                        <a @click="sliderSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M5.005 10.19a1 1 0 0 1 1 1v.233l5.998 3.464L18 11.423v-.232a1 1 0 1 1 2 0V12a1 1 0 0 1-.5.866l-6.997 4.042a1 1 0 0 1-1 0l-6.998-4.042a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1ZM5 15.15a1 1 0 0 1 1 1v.232l5.997 3.464 5.998-3.464v-.232a1 1 0 1 1 2 0v.81a1 1 0 0 1-.5.865l-6.998 4.042a1 1 0 0 1-1 0L4.5 17.824a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1Z"
                                    clip-rule="evenodd" />
                                <path
                                    d="M12.503 2.134a1 1 0 0 0-1 0L4.501 6.17A1 1 0 0 0 4.5 7.902l7.002 4.047a1 1 0 0 0 1 0l6.998-4.04a1 1 0 0 0 0-1.732l-6.997-4.042Z" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Slider</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || isAdmin1 || dataUser.acl.acl_DOWNLOAD">
                    <li :class="{ 'bg-gray-200 rounded-lg': ['list-unduhan', 'create-unduhan'].includes(currentPage) }">
                        <a @click="unduhanSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M13 11.15V4a1 1 0 1 0-2 0v7.15L8.78 8.374a1 1 0 1 0-1.56 1.25l4 5a1 1 0 0 0 1.56 0l4-5a1 1 0 1 0-1.56-1.25L13 11.15Z"
                                    clip-rule="evenodd" />
                                <path fill-rule="evenodd"
                                    d="M9.657 15.874 7.358 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.358l-2.3 2.874a3 3 0 0 1-4.685 0ZM17 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Download</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || isAdmin1 || dataUser.acl.acl_SEMINAR">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-seminar-kursus', 'create-seminar-kursus', 'edit-seminar-kursus'].includes(currentPage) }">
                        <a @click="seminarAndCoursesSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M12.512 8.72a2.46 2.46 0 0 1 3.479 0 2.461 2.461 0 0 1 0 3.479l-.004.005-1.094 1.08a.998.998 0 0 0-.194-.272l-3-3a1 1 0 0 0-.272-.193l1.085-1.1Zm-2.415 2.445L7.28 14.017a1 1 0 0 0-.289.702v2a1 1 0 0 0 1 1h2a1 1 0 0 0 .703-.288l2.851-2.816a.995.995 0 0 1-.26-.189l-3-3a.998.998 0 0 1-.19-.26Z"
                                    clip-rule="evenodd" />
                                <path fill-rule="evenodd"
                                    d="M7 3a1 1 0 0 1 1 1v1h3V4a1 1 0 1 1 2 0v1h3V4a1 1 0 1 1 2 0v1h1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h1V4a1 1 0 0 1 1-1Zm10.67 8H19v8H5v-8h3.855l.53-.537a1 1 0 0 1 .87-.285c.097.015.233.13.277.087.045-.043-.073-.18-.09-.276a1 1 0 0 1 .274-.873l1.09-1.104a3.46 3.46 0 0 1 4.892 0l.001.002A3.461 3.461 0 0 1 17.67 11Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Seminars and Courses</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || isAdmin1 || dataUser.acl.acl_DONASI">
                    <li :class="{ 'bg-gray-200 rounded-lg': ['list-donasi'].includes(currentPage) }">
                        <a @click="donasiSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M20 10H4v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8ZM9 13v-1h6v1a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1Z"
                                    clip-rule="evenodd" />
                                <path d="M2 6a2 2 0 0 1 2-2h16a2 2 0 1 1 0 4H4a2 2 0 0 1-2-2Z" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Donation</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || dataUser.acl.acl_COMMUNITY">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-community', 'list-community-search', 'create-community', 'list-trash-community', 'list-trash-community-search', 'edit-community', 'list-pic-community', 'list-pic-community-search'].includes(currentPage) }">
                        <a @click="communitySuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                                    clip-rule="evenodd" />
                            </svg>

                            <span class="flex-1 ms-3 whitespace-nowrap">Community</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin || isAdmin || dataUser.acl.acl_SUB_COMMUNITY">
                    <li
                        :class="{ 'bg-gray-200 rounded-lg': ['list-sub-community', 'list-sub-community-search', 'create-sub-community', 'edit-sub-community', 'list-member-sub-community', 'list-member-sub-community-search'].includes(currentPage) }">
                        <a @click="subCommunitySuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Sub Community</span>
                        </a>
                    </li>
                </span>
                <span v-if="isSuperAdmin">
                    <li :class="{ 'bg-gray-200 rounded-lg': ['list-pic-agensi'].includes(currentPage) }">
                        <a @click="settingSuperAdmin" href=""
                            class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M9.586 2.586A2 2 0 0 1 11 2h2a2 2 0 0 1 2 2v.089l.473.196.063-.063a2.002 2.002 0 0 1 2.828 0l1.414 1.414a2 2 0 0 1 0 2.827l-.063.064.196.473H20a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-.089l-.196.473.063.063a2.002 2.002 0 0 1 0 2.828l-1.414 1.414a2 2 0 0 1-2.828 0l-.063-.063-.473.196V20a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-.089l-.473-.196-.063.063a2.002 2.002 0 0 1-2.828 0l-1.414-1.414a2 2 0 0 1 0-2.827l.063-.064L4.089 15H4a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h.09l.195-.473-.063-.063a2 2 0 0 1 0-2.828l1.414-1.414a2 2 0 0 1 2.827 0l.064.063L9 4.089V4a2 2 0 0 1 .586-1.414ZM8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
                                    clip-rule="evenodd" />
                            </svg>
                            <span class="flex-1 ms-3 whitespace-nowrap">Setting</span>
                        </a>
                    </li>
                </span>
            </ul>
        </div>
    </aside>
</template>

<script>
import { initFlowbite } from 'flowbite'

export default {
    data() {
        return {
            isSuperAdmin: false,
            isAdmin1: false,
            isAdmin: false,
            isAdminSubKomunitas: false,
            googleLogin: null,
            currentPage: '',
        }
    },
    mounted() {
        initFlowbite()
        this.isSuperAdmin = sessionStorage.getItem('role') === 'superadmin'
        this.isAdmin1 = sessionStorage.getItem('role') === 'admin1'
        this.isAdmin = sessionStorage.getItem('role') === 'admin'
        this.isAdminSubKomunitas = sessionStorage.getItem('role') === 'adminSubKomunitas'
        this.currentPage = this.$route.name
    },
    computed: {
        userDataLogin() {
            return this.$store.getters.getUserDataLogin
        },
        dataUser() {
            const data = sessionStorage.getItem('userData')
            const jsonParse = JSON.parse(data)
            return jsonParse
        },
        dataAgensi() {
            const data = sessionStorage.getItem('agensi')
            const jsonParse = JSON.parse(data)
            return jsonParse
        },
        dataSubKomunitas() {
            const data = sessionStorage.getItem('subKomunitas')
            const jsonParse = JSON.parse(data)
            return jsonParse
        },
        // gambarUser() {
        //     const data = sessionStorage.getItem('credential')
        //     const jsonParse = JSON.parse(data)
        //     return jsonParse
        // }
    },
    watch: {
        '$route'(to) {
            this.currentPage = to.name
        }
    },
    methods: {
        async refreshToken() {
            console.log("ok")
        },
        homeRole() {
            sessionStorage.removeItem('agensi')
            sessionStorage.removeItem('role')
            sessionStorage.removeItem('subKomunitas')
            this.$router.push({ name: "role" })
        },
        dashboardSuperAdmin() {
            const agensi = sessionStorage.getItem('agensi')
            const jsonParse = JSON.parse(agensi)
            this.$router.push({ name: "dashboard", params: { idAgensi: jsonParse.id, agensi: jsonParse.namaAgensi.replace(/\s+/g, '-') } })
        },
        reflectionSuperAdmin() {
            this.$router.push({ name: "list-reflection", params: { agensi: this.$route.params.idAgensi, isSearch: false, language: "id", pageNumber: 1 } })
        },
        articleSuperAdmin() {
            this.$router.push({ name: "list-article", params: { agensi: this.$route.params.idAgensi, isSearch: false, language: "id", pageNumber: 1 } })
        },
        agendaSuperAdmin() {
            this.$router.push({ name: "list-agenda", params: { agensi: this.$route.params.idAgensi, isSearch: false, language: "id", pageNumber: 1 } })
        },
        communitySuperAdmin() {
            this.$router.push({ name: "list-community", params: { agensi: this.$route.params.idAgensi, isSearch: false, pageNumber: 1 } })
        },
        playlistSuperAdmin() {
            this.$router.push({ name: "list-playlist", params: { agensi: this.$route.params.idAgensi, isSearch: false, pageNumber: 1 } })
        },
        topicSuperAdmin() {
            this.$router.push({ name: "list-topic", params: { agensi: this.$route.params.idAgensi, isSearch: false, pageNumber: 1 } })
        },
        pokokDoaSuperAdmin() {
            const agensi = sessionStorage.getItem('agensi')
            const jsonParse = JSON.parse(agensi)
            this.$router.push({ name: "list-pokok-doa", params: { agensi: jsonParse.id, pageNumber: 1 } })
        },
        subCommunitySuperAdmin() {
            this.$router.push({ name: "list-sub-community", params: { agensi: this.$route.params.idAgensi, isSearch: false, pageNumber: 1 } })
        },
        produkSuperAdmin() {
            this.$router.push({ name: "list-produk", params: { agensi: this.$route.params.idAgensi, isSearch: false, pageNumber: 1 } })
        },
        sliderSuperAdmin() {
            this.$router.push({ name: "list-slider", params: { agensi: this.$route.params.idAgensi, isSearch: false, pageNumber: 1 } })
        },
        unduhanSuperAdmin() {
            this.$router.push({ name: "list-unduhan", params: { agensi: this.$route.params.idAgensi, pageNumber: 1 } })
        },
        seminarAndCoursesSuperAdmin() {
            this.$router.push({ name: "list-seminar-kursus", params: { agensi: this.$route.params.idAgensi, pageNumber: 1 } })
        },
        donasiSuperAdmin() {
            this.$router.push({ name: "list-donasi", params: { agensi: this.$route.params.idAgensi, pageNumber: 1 } })
        },
        settingSuperAdmin() {
            this.$router.push({ name: 'list-pic-agensi', params: { agensi: this.$route.params.idAgensi, idCommunity: 1, isSearch: false, pageNumber: 1 } })
        },
        dashboardSubKomunitas() {
            const subKomunitas = sessionStorage.getItem('subKomunitas')
            const jsonParse = JSON.parse(subKomunitas)
            this.$router.push({ name: "dashboard-sub-komunitas", params: { idSubKomunitas: jsonParse.id, subKomunitas: jsonParse.namaSubKomunitas.replace(/\s+/g, '-') } })
        },
        reflectionSubKomunitas() {
            this.$router.push({ name: "list-reflection-sub-komunitas", params: { subKomunitas: this.$route.params.idSubKomunitas, isSearch: false, language: "id", pageNumber: 1 } })
        },
        agendaSubKomunitas() {
            this.$router.push({ name: "list-agenda-sub-komunitas", params: { subKomunitas: this.$route.params.idSubKomunitas, isSearch: false, language: "id", pageNumber: 1 } })
        },
        broadcastSubKomunitas() {
            this.$router.push({ name: "create-broadcast-sub-komunitas", params: { subKomunitas: this.$route.params.idSubKomunitas, isSearch: false, language: "id", pageNumber: 1 } })
        },
        logout() {
            sessionStorage.removeItem('credential')
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('userData')
            sessionStorage.removeItem('role')
            sessionStorage.removeItem('agensi')
            sessionStorage.removeItem('subKomunitas')
            this.$router.push({ name: 'login' })
        }
    }
}
</script>