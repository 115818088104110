import { createStore } from 'vuex'
import axios from 'axios'
import donasi from './donasi'

const url_api = process.env.VUE_APP_API_URL

export default createStore({
  state: {
    //POKOK DOA
    createPokokDoa: null,
    listPokokDoa: [],
    listPokokDoaKhusus: [],
    editPokokDoa: null,
    //REFLECTION
    createReflection: null,
    reflection: [],
    editGambarReflection: null,
    editAudioReflection: null,
    setPublishReflection: null,
    detailReflection: null,
    editReflection: null,
    createReflectionSubKomunitas: null,
    reflectionSubKomunitas: [],
    //LOGIN
    login: null,
    loginByGoogle: null,
    //TOPIK
    topics: [],
    listTopic2: [],
    editTopic: null,
    createTopic: null,
    detailPokokDoa: null,
    deletePokokDoa: null,
    //AGENSI - COMMUNITY
    agensi: [],
    usersRoleAgensi: null,
    komunitas: [],
    allAgensi: [],
    editGambarAgensi: null,
    editMouAgensi: null,
    createAgensi: null,
    editAgensi: null,
    detailAgensi: null,
    setStatusAktifAgensi: null,
    listPICAgensi: [],
    listPICAgensi2: [],
    addPICAgensi: null,
    addPICAgensi2: null,
    deletePICAgensi: null,
    deletePICAgensi2: null,
    //SUB KOMUNITAS
    subKomunitas: [],
    listSubKomunitas: [],
    editGambarSubKomunitas: null,
    createSubKomunitas: null,
    editSubKomunitas: null,
    setStatusAktifSubKomunitas: null,
    detailSubKomunitas: null,
    listMemberSubKomunitas: [],
    addMemberSubKomunitas: null,
    deleteMemberSubKomunitas: null,
    setStatusConfirmMemberSubKomunitas: null,
    changeRoleMemberSubKomunitas: null,
    //ARTICLE
    article: [],
    setPublishArticle: null,
    editGambarArticle: null,
    createArticle: null,
    detailArticle: null,
    editArticle: null,
    //NEW VIDEO
    listPlaylistVideo: [],
    createPlaylistVideo: null,
    editPlaylistVideo: null,
    setPublishPlaylistVideo: null,
    listNewVideo: [],
    editNewVideo: null,
    createNewVideo: null,
    deleteNewVideo: null,
    //AGENDA
    listAgenda: [],
    createAgenda: null,
    createDetailAgenda: null,
    editGambarAgenda: null,
    detailAgenda: [],
    editDetailAgenda: null,
    deleteAgenda: null,
    detailMasterAgenda: null,
    editMasterAgenda: null,
    listAgendSubKomunitas: [],
    createAgendaSubKomunitas: null,
    listAgendaCopySubKomunitas: [],
    copyAgenda: null,
    deleteAgendaCopy: null,
    //PRODUK
    createProduk: null,
    listProduk: [],
    addThumbnailProduk: null,
    listThumbnailProduk: [],
    deleteThumbnailProduk: null,
    setStatusPublishProduk: null,
    editProduk: null,
    detailProduk: null,
    editBannerProduk: null,
    editBannerMobileProduk: null,
    //SLIDER
    listSlider: [],
    createSlider: null,
    editSlider: null,
    editDefaultSlider: null,
    editMobileSlider: null,
    deleteSlider: null,
    moveSlider: null,
    //ACL
    listACL: [],
    changeStatusACL: null,
    //BROADCAST
    createBroadCast: null,
    //UNDUHAN
    listUnduhan: [],
    createUnduhan: null,
    deleteUnduhan: null,
    editUnduhan: null,
    //EDITOR TEXT
    editorText: null,
    //SEMINAR
    listSeminar: [],
    createSeminar: null,
    deleteSeminar: null,
    editSeminar: null,
    detailSeminar: null,
    //USERS
    listUsers: [],
  },
  getters: {
    //TOPIK
    getTopics: (state) => state.topics,
    getListTopic2: (state) => state.listTopic2,
    getCreateTopic: (state) => state.createTopic,
    getEditTopic: (state) => state.editTopic,
    //REFLECTION
    getReflection: (state) => state.reflection,
    getDetailReflection: (state) => state.detailReflection,
    getReflectionSubKomunitas: (state) => state.reflectionSubKomunitas,
    //LOGIN
    getUserDataLoginByGoogle: (state) => state.loginByGoogle,
    getUserDataLogin: (state) => state.login,
    //AGENSI - COMMUNITY
    getAgensi: (state) => state.agensi,
    getUsersRoleAgensi: (state) => state.usersRoleAgensi,
    getKomunitas: (state) => state.komunitas,
    getAllAgensi: (state) => state.allAgensi,
    getDetailAgensi: (state) => state.detailAgensi,
    getPICAgensi: (state) => state.listPICAgensi,
    getPICAgensi2: (state) => state.listPICAgensi2,
    getAddPICAgensi: (state) => state.addPICAgensi,
    getAddPICAgensi2: (state) => state.addPICAgensi2,
    //SUB KOMUNITAS
    getSubKomunitas: (state) => state.subKomunitas,
    getListSubKomunitas: (state) => state.listSubKomunitas,
    getDetailSubKomunitas: (state) => state.detailSubKomunitas,
    getListMemberSubKomunitas: (state) => state.listMemberSubKomunitas,
    getAddMemberSubKomunitas: (state) => state.addMemberSubKomunitas,
    //ARTICLE
    getArticle: (state) => state.article,
    getDetailArticle: (state) => state.detailArticle,
    //NEW VIDEO
    getPlaylistVideo: (state) => state.listPlaylistVideo,
    getCreatePlaylistVideo: (state) => state.createPlaylistVideo,
    getEditPlaylistVideo: (state) => state.editPlaylistVideo,
    getListNewVideo: (state) => state.listNewVideo,
    //POKOK DOA
    getListPokokDoa: (state) => state.listPokokDoa,
    getListPokokDoaKhusus: (state) => state.listPokokDoaKhusus,
    getDetailPokokDoa: (state) => state.detailPokokDoa,
    //AGENDA
    getListAgenda: (state) => state.listAgenda,
    getDetailAgenda: (state) => state.detailAgenda,
    getDetailMasterAgenda: (state) => state.detailMasterAgenda,
    getListAgendaSubKomunitas: (state) => state.listAgendSubKomunitas,
    getListAgendaCopySubKomunitas: (state) => state.listAgendaCopySubKomunitas,
    getCopyAgenda: (state) => state.copyAgenda,
    //PRODUK
    getListProduk: (state) => state.listProduk,
    getAddThumbnailProduk: (state) => state.addThumbnailProduk,
    getListThumbnailProduk: (state) => state.listThumbnailProduk,
    getDetailProduk: (state) => state.detailProduk,
    //SLIDER
    getListSlider: (state) => state.listSlider,
    //ACL
    getListACL: (state) => state.listACL,
    //UNDUHAN
    getListUnduhan: (state) => state.listUnduhan,
    //EDITOR TEXT
    getEditorText: (state) => state.editorText,
    //SEMINAR
    getListSeminar: (state) => state.listSeminar,
    getDetailSeminar: (state) => state.detailSeminar,
    getListUsers: (state) => state.listUsers,
  },
  mutations: {
    //LOGIN
    SET_LOGIN(state, payload) {
      state.login = payload
    },
    SET_LOGIN_BY_GOOGLE(state, payload) {
      state.loginByGoogle = payload
    },
    //TOPIK
    SET_TOPIC(state, payload) {
      state.topics = payload
    },
    SET_LIST_TOPIC2(state, payload) {
      state.listTopic2 = payload
    },
    SET_EDIT_TOPIC(state, payload) {
      state.editTopic = payload
    },
    SET_CREATE_TOPIC(state, payload) {
      state.createTopic = payload
    },
    //POKOK DOA
    SET_CREATE_POKOK_DOA(state, payload) {
      state.createPokokDoa = payload
    },
    SET_LIST_POKOK_DOA(state, payload) {
      state.listPokokDoa = payload
    },
    SET_LIST_POKOK_DOA_KHUSUS(state, payload) {
      state.listPokokDoaKhusus = payload
    },
    SET_DETAIL_POKOK_DOA(state, payload) {
      state.detailPokokDoa = payload
    },
    SET_EDIT_POKOK_DOA(state, payload) {
      state.editPokokDoa = payload
    },
    SET_DELETE_POKOK_DOA(state, payload) {
      state.deletePokokDoa = payload
    },
    //REFLECTION
    SET_CREATE_REFLECTION(state, payload) {
      state.createReflection = payload
    },
    SET_LIST_REFLECTION(state, payload) {
      state.reflection = payload
    },
    SET_EDIT_GAMBAR_REFLECTION(state, payload) {
      state.editGambarReflection = payload
    },
    SET_EDIT_AUDIO_REFLECTION(state, payload) {
      state.editAudioReflection = payload
    },
    SET_PUBLISH_REFLECTION(state, payload) {
      state.setPublishReflection = payload
    },
    SET_DETAIL_REFLECTION(state, payload) {
      state.detailReflection = payload
    },
    SET_EDIT_REFLECTION(state, payload) {
      state.editReflection = payload
    },
    SET_CREATE_REFLECTION_SUB_KOMUNITAS(state, payload) {
      state.createReflectionSubKomunitas = payload
    },
    SET_LIST_REFLECTION_SUB_KOMUNITAS(state, payload) {
      state.reflectionSubKomunitas = payload
    },
    //AGENSI - COMMUNITY
    SET_LIST_AGENSI(state, payload) {
      state.agensi = payload
    },
    SET_USERS_ROLE_AGENSI(state, payload) {
      state.usersRoleAgensi = payload
    },
    SET_LIST_KOMUNITAS(state, payload) {
      state.komunitas = payload
    },
    SET_LIST_ALL_AGENSI(state, payload) {
      state.allAgensi = payload
    },
    SET_EDIT_GAMBAR_AGENSI(state, payload) {
      state.editGambarAgensi = payload
    },
    SET_EDIT_MOU_AGENSI(state, payload) {
      state.editMouAgensi = payload
    },
    SET_CREATE_AGENSI(state, payload) {
      state.createAgensi = payload
    },
    SET_EDIT_AGENSI(state, payload) {
      state.editAgensi = payload
    },
    SET_DETAIL_AGENSI(state, payload) {
      state.detailAgensi = payload
    },
    SET_STATUS_AKTIF_AGENSI(state, payload) {
      state.setStatusAktifAgensi = payload
    },
    SET_LIST_PIC_AGENSI(state, payload) {
      state.listPICAgensi = payload
    },
    SET_LIST_PIC_AGENSI2(state, payload) {
      state.listPICAgensi2 = payload
    },
    SET_ADD_PIC_AGENSI(state, payload) {
      state.addPICAgensi = payload
    },
    SET_ADD_PIC_AGENSI2(state, payload) {
      state.addPICAgensi2 = payload
    },
    SET_DELETE_PIC_AGENSI(state, payload) {
      state.deletePICAgensi = payload
    },
    SET_DELETE_PIC_AGENSI2(state, payload) {
      state.deletePICAgensi2 = payload
    },
    //SUB KOMUNITAS
    SET_LIST_SUB_KOMUNITAS(state, payload) {
      state.subKomunitas = payload
    },
    SET_LIST_SUB2_KOMUNITAS(state, payload) {
      state.listSubKomunitas = payload
    },
    SET_EDIT_GAMBAR_SUB_KOMUNITAS(state, payload) {
      state.editGambarSubKomunitas = payload
    },
    SET_CREATE_SUB_KOMUNITAS(state, payload) {
      state.createSubKomunitas = payload
    },
    SET_EDIT_SUB_KOMUNITAS(state, payload) {
      state.editSubKomunitas = payload
    },
    SET_STATUS_AKTIF_SUB_KOMUNITAS(state, payload) {
      state.setStatusAktifSubKomunitas = payload
    },
    SET_DETAIL_SUB_KOMUNITAS(state, payload) {
      state.detailSubKomunitas = payload
    },
    SET_LIST_MEMBER_SUB_KOMUNITAS(state, payload) {
      state.listMemberSubKomunitas = payload
    },
    SET_ADD_MEMBER_SUB_KOMUNITAS(state, payload) {
      state.addMemberSubKomunitas = payload
    },
    SET_DELETE_MEMBER_SUB_KOMUNITAS(state, payload) {
      state.deleteMemberSubKomunitas = payload
    },
    SET_STATUS_CONFIRM_MEMBER_SUB_KOMUNITAS(state, payload) {
      state.setStatusConfirmMemberSubKomunitas = payload
    },
    SET_CHANGE_ROLE_MEMBER_SUB_KOMUNITAS(state, payload) {
      state.changeRoleMemberSubKomunitas = payload
    },
    //ARTICLE
    SET_LIST_ARTICLE(state, payload) {
      state.article = payload
    },
    SET_PUBLISH_ARTICLE(state, payload) {
      state.setPublishArticle = payload
    },
    SET_EDIT_GAMBAR_ARTICLE(state, payload) {
      state.editGambarArticle = payload
    },
    SET_CREATE_ARTICLE(state, payload) {
      state.createArticle = payload
    },
    SET_DETAIL_ARTICLE(state, payload) {
      state.detailArticle = payload
    },
    SET_EDIT_ARTICLE(state, payload) {
      state.editArticle = payload
    },
    //NEW VIDEO
    SET_LIST_PLAYLIST_VIDEO(state, payload) {
      state.listPlaylistVideo = payload
    },
    SET_CREATE_PLAYLIST_VIDEO(state, payload) {
      state.createPlaylistVideo = payload
    },
    SET_EDIT_PLAYLIST_VIDEO(state, payload) {
      state.editPlaylistVideo = payload
    },
    SET_PUBLISH_PLAYLIST_VIDEO(state, payload) {
      state.setPublishPlaylistVideo = payload
    },
    SET_LIST_NEW_VIDEO(state, payload) {
      state.listNewVideo = payload
    },
    SET_EDIT_NEW_VIDEO(state, payload) {
      state.editNewVideo = payload
    },
    SET_CREATE_NEW_VIDEO(state, payload) {
      state.createNewVideo = payload
    },
    SET_DELETE_NEW_VIDEO(state, payload) {
      state.deleteNewVideo = payload
    },
    //AGENDA
    SET_LIST_AGENDA(state, payload) {
      state.listAgenda = payload
    },
    SET_CREATE_AGENDA(state, payload) {
      state.createAgenda = payload
    },
    SET_CREATE_DETAIL_AGENDA(state, payload) {
      state.createDetailAgenda = payload
    },
    SET_EDIT_GAMBAR_AGENDA(state, payload) {
      state.editGambarAgenda = payload
    },
    SET_DETAIL_AGENDA(state, payload) {
      state.detailAgenda = payload
    },
    SET_EDIT_DETAIL_AGENDA(state, payload) {
      state.editDetailAgenda = payload
    },
    SET_DELETE_AGENDA(state, payload) {
      state.deleteAgenda = payload
    },
    SET_DETAIL_MASTER_AGENDA(state, payload) {
      state.detailMasterAgenda = payload
    },
    SET_EDIT_MASTER_AGENDA(state, payload) {
      state.editMasterAgenda = payload
    },
    SET_LIST_AGENDA_SUB_KOMUNITAS(state, payload) {
      state.listAgendSubKomunitas = payload
    },
    SET_CREATE_AGENDA_SUB_KOMUNITAS(state, payload) {
      state.createAgendaSubKomunitas = payload
    },
    SET_LIST_AGENDA_COPY_SUB_KOMUNITAS(state, payload) {
      state.listAgendaCopySubKomunitas = payload
    },
    SET_COPY_AGENDA(state, payload) {
      state.copyAgenda = payload
    },
    SET_DELETE_AGENDA_COPY(state, payload) {
      state.deleteAgendaCopy = payload
    },
    //PRODUK
    SET_CREATE_PRODUK(state, payload) {
      state.createProduk = payload
    },
    SET_LIST_PRODUK(state, payload) {
      state.listProduk = payload
    },
    SET_ADD_THUMBNAIL_PRODUK(state, payload) {
      state.addThumbnailProduk = payload
    },
    SET_LIST_THUMBNAIL_PRODUK(state, payload) {
      state.listThumbnailProduk = payload
    },
    SET_DELETE_THUMBNAIL_PRODUK(state, payload) {
      state.deleteThumbnailProduk = payload
    },
    SET_STATUS_PUBLISH_PRODUK(state, payload) {
      state.setStatusPublishProduk = payload
    },
    SET_EDIT_PRODUK(state, payload) {
      state.editProduk = payload
    },
    SET_DETAIL_PRODUK(state, payload) {
      state.detailProduk = payload
    },
    SET_EDIT_BANNER_PRODUK(state, payload) {
      state.editBannerProduk = payload
    },
    SET_EDIT_BANNER_MOBILE_PRODUK(state, payload) {
      state.editBannerMobileProduk = payload
    },
    SET_LIST_SLIDER(state, payload) {
      state.listSlider = payload
    },
    SET_CREATE_SLIDER(state, payload) {
      state.createSlider = payload
    },
    SET_EDIT_SLIDER(state, payload) {
      state.editSlider = payload
    },
    SET_EDIT_DEFAULT_SLIDER(state, payload) {
      state.editDefaultSlider = payload
    },
    SET_EDIT_MOBILE_SLIDER(state, payload) {
      state.editMobileSlider = payload
    },
    SET_DELETE_SLIDER(state, payload) {
      state.deleteSlider = payload
    },
    SET_MOVE_SLIDER(state, payload) {
      state.moveSlider = payload
    },
    //ACL
    SET_LIST_ACL(state, payload) {
      state.listACL = payload
    },
    SET_CHANGE_STATUS_ACL(state, payload) {
      state.changeStatusACL = payload
    },
    SET_CREATE_BROADCAST(state, payload) {
      state.createBroadCast = payload
    },
    //UNDUHAN
    SET_LIST_UNDUHAN(state, payload) {
      state.listUnduhan = payload
    },
    SET_CREATE_UNDUHAN(state, payload) {
      state.createUnduhan = payload
    },
    SET_DELETE_UNDUHAN(state, payload) {
      state.deleteUnduhan = payload
    },
    SET_EDIT_UNDUHAN(state, payload) {
      state.editUnduhan = payload
    },
    //EDITOR TEXT
    SET_EDITOR_TEXT(state, payload) {
      state.editorText = payload
    },
    //SEMINAR
    SET_LIST_SEMINAR(state, payload) {
      state.listSeminar = payload
    },
    SET_CREATE_SEMINAR(state, payload) {
      state.createSeminar = payload
    },
    SET_DELETE_SEMINAR(state, payload) {
      state.createSeminar = payload
    },
    SET_EDIT_SEMINAR(state, payload) {
      state.editSeminar = payload
    },
    SET_DETAIL_SEMINAR(state, payload) {
      state.detailSeminar = payload
    },
    //USERS
    SET_LIST_USERS(state, payload) {
      state.listUsers = payload
    },
  },
  actions: {
    //LOGIN
    async login({ commit }, payload) {
      const { email, password } = payload
      console.log(password)
      try {
        const formdata = new FormData()
        formdata.append("email", email)

        await axios.post(url_api + "user", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_LOGIN', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async loginByGoogle({ commit }, payload) {
      const { email } = payload
      try {
        const formdata = new FormData()
        formdata.append("email", email)

        await axios.post(url_api + "user", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_LOGIN_BY_GOOGLE', response.data)
          })

      } catch (error) {
        return error
      }
    },
    //TOPIK
    async listTopic({ commit }) {
      try {
        await axios.get(url_api + "list-topik", {
          headers: {
            "Content-Type": "application/json"
          }
        }).then((response) => {
          commit('SET_TOPIC', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async listTopic2({ commit }, payload) {
      const { isSearch, search, index, limit } = payload
      try {
        await axios.get(url_api + "list-topik2", {
          params: {
            isSearch: isSearch,
            search: search,
            index: index,
            limit: limit
          },
          headers: {
            "Content-Type": "application/json"
          }
        }).then((response) => {
          commit('SET_LIST_TOPIC2', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async editTopic({ commit }, payload) {
      const { id, topic } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("topik", topic)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-topik", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_EDIT_TOPIC', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async createTopic({ commit }, payload) {
      const { topic } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("topik", topic)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-topik", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_TOPIC', response.data)
          })

      } catch (error) {
        return error
      }
    },
    //POKOK DOA
    async createPokokDoa({ commit }, payload) {
      const { judul, deskripsi, jadwal, jenis } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("judul", judul)
        formdata.append("deskripsi", deskripsi)
        formdata.append("jadwal", jadwal)
        formdata.append("jenis", jenis)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-pokok-doa", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_POKOK_DOA', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editPokokDoa({ commit }, payload) {
      const { id, judul, deskripsi, jadwal, jenis } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("judul", judul)
        formdata.append("deskripsi", deskripsi)
        formdata.append("jadwal", jadwal)
        formdata.append("jenis", jenis)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-pokok-doa", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_EDIT_POKOK_DOA', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async listPokokDoa({ commit }, payload) {
      const { index, limit, jenis } = payload
      try {
        await axios.get(url_api + "list-pokok-doa", {
          params: {
            index: index,
            limit: limit,
            jenis: jenis,
            publish: true,
            orderBy: "jadwal",
          },
          headers: {
            "Content-Type": "application/json"
          }
        }).then((response) => {
          commit('SET_LIST_POKOK_DOA', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async listPokokDoaKhusus({ commit }, payload) {
      const { index, limit, jenis } = payload
      try {
        await axios.get(url_api + "list-pokok-doa", {
          params: {
            index: index,
            limit: limit,
            jenis: jenis,
            publish: true,
            orderBy: "jadwal",
          },
          headers: {
            "Content-Type": "application/json"
          }
        }).then((response) => {
          commit('SET_LIST_POKOK_DOA_KHUSUS', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async detailPokokDoa({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "detail-pokok-doa",
          {
            params: {
              id: id,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DETAIL_POKOK_DOA', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async deletePokokDoa({ commit }, payload) {
      const { id } = payload
      try {
        await axios.delete(url_api + "delete-pokok-doa", {
          params: {
            id: id,
          },
          headers: {
            "Content-Type": "application/json"
          }
        }).then((response) => {
          commit('SET_DELETE_POKOK_DOA', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    //REFLECTION
    async listReflection({ commit }, payload) {
      const { isSearch, search, language, idAgensi, publish, index, limit } = payload

      try {
        await axios.get(url_api + "list-renungan",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idAgensi: idAgensi,
              language: language,
              publish: publish,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_REFLECTION', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async listReflectionSubKomunitas({ commit }, payload) {
      const { isSearch, search, language, idSubKomunitas, publish, index, limit } = payload

      try {
        await axios.get(url_api + "list-renungan-sub-komunitas",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idSubKomunitas: idSubKomunitas,
              language: language,
              publish: publish,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_REFLECTION_SUB_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async detailReflection({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "detail-renungan",
          {
            params: {
              id: id,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DETAIL_REFLECTION', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async createReflection({ commit }, payload) {
      const { language, title, verse, schedule, video, author, topic, tag, content, imageUpload, idAgensi } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("judulRenungan", title)
        formdata.append("natsAlkitabRenungan", verse)
        formdata.append("deskripsiRenungan", content)
        formdata.append("gambarRenungan", imageUpload)
        formdata.append("video", video)
        formdata.append("authorRenungan", author)
        formdata.append("jadwalRenungan", schedule)
        formdata.append("jenis", "PUBLIC")
        formdata.append("idAgensi", idAgensi)
        formdata.append("language", language)
        formdata.append("createdBy", userId)
        formdata.append("topik", topic)
        formdata.append("tag", tag)

        await axios.post(url_api + "create-renungan", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_REFLECTION', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async createReflectionSubKomunitas({ commit }, payload) {
      const { language, title, verse, schedule, video, author, topic, tag, content, imageUpload, idSubKomunitas } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("judulRenungan", title)
        formdata.append("natsAlkitabRenungan", verse)
        formdata.append("deskripsiRenungan", content)
        formdata.append("gambarRenungan", imageUpload)
        formdata.append("video", video)
        formdata.append("authorRenungan", author)
        formdata.append("jadwalRenungan", schedule)
        formdata.append("jenis", "PRIVATE")
        formdata.append("idSubKomunitas", idSubKomunitas)
        formdata.append("language", language)
        formdata.append("createdBy", userId)
        formdata.append("topik", topic)
        formdata.append("tag", tag)

        await axios.post(url_api + "create-renungan-sub-komunitas", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_REFLECTION_SUB_KOMUNITAS', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editReflection({ commit }, payload) {
      const { id, judulRenungan, natsAlkitabRenungan, deskripsiRenungan, video, authorRenungan, jadwalRenungan, language, topic, tag } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("judulRenungan", judulRenungan)
        formdata.append("natsAlkitabRenungan", natsAlkitabRenungan)
        formdata.append("deskripsiRenungan", deskripsiRenungan)
        formdata.append("video", video)
        formdata.append("authorRenungan", authorRenungan)
        formdata.append("jadwalRenungan", jadwalRenungan)
        formdata.append("jenis", "PUBLIC")
        formdata.append("language", language)
        formdata.append("updatedBy", userId)
        formdata.append("topik", topic)
        formdata.append("tag", tag)

        await axios.post(url_api + "edit-renungan", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_EDIT_REFLECTION', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editGambarReflection({ commit }, payload) {
      const { id, gambarRenungan } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null


        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("gambarRenungan", gambarRenungan)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-gambar-renungan", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_GAMBAR_REFLECTION', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async editAudioReflection({ commit }, payload) {
      const { id, audioRenungan } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("audioRenungan", audioRenungan)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-audio-renungan", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_AUDIO_REFLECTION', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async setPublishReflection({ commit }, payload) {
      const { id, publish } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("publish", publish)
        formdata.append("deletedBy", userId)

        await axios.post(url_api + "set-publish-renungan", formdata,
          {
            headers: {

            },
          }
        ).then((response) => {
          commit('SET_PUBLISH_REFLECTION', response.data)
        })
      } catch (error) {
        return error
      }
    },
    //AGENSI - COMMUNITY
    async listAgensi({ commit }, payload) {
      const { index, limit } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        await axios.get(url_api + "agensi",
          {
            params: {
              idUser: userId,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_AGENSI', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async checkUsersRoleAgensi({ commit }, payload) {
      const { idAgensi } = payload

      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        await axios.get(url_api + "check-users-role-agensi",
          {
            params: {
              idUser: userId,
              idAgensi: idAgensi,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_USERS_ROLE_AGENSI', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async listKomunitas({ commit }, payload) {
      const { index, limit } = payload
      try {
        const userDataString = sessionStorage.getItem('userData');
        const userData = userDataString ? JSON.parse(userDataString) : null;
        const userId = userData?.id ?? null;

        await axios.get(url_api + "komunitas",
          {
            params: {
              idUser: userId,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async allListAgensi({ commit }, payload) {
      const { isSearch, search, statusAktif, index, limit } = payload

      try {
        await axios.get(url_api + "list-agensi",
          {
            params: {
              isSearch: isSearch,
              search: search,
              statusAktif: statusAktif,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_ALL_AGENSI', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async editGambarAgensi({ commit }, payload) {
      const { id, gambarAgensi } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null


        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("gambarAgensi", gambarAgensi)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-gambar-agensi", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_GAMBAR_AGENSI', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async editMouAgensi({ commit }, payload) {
      const { id, mouAgensi } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null


        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("mouAgensi", mouAgensi)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-mou-agensi", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_MOU_AGENSI', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async createAgensi({ commit }, payload) {
      const { namaAgensi, namaSingkatanAgensi, alamatAgensi, nomorTeleponAgensi, gambarAgensi, mouAgensi } = payload
      try {

        const formdata = new FormData()
        formdata.append("namaAgensi", namaAgensi)
        formdata.append("namaSingkatanAgensi", namaSingkatanAgensi)
        formdata.append("alamatAgensi", alamatAgensi)
        formdata.append("nomorTeleponAgensi", nomorTeleponAgensi)
        formdata.append("gambarAgensi", gambarAgensi)
        formdata.append("mouAgensi", mouAgensi)

        await axios.post(url_api + "create-agensi", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_AGENSI', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editAgensi({ commit }, payload) {
      const { id, namaAgensi, namaSingkatanAgensi, alamatAgensi, nomorTeleponAgensi } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("namaAgensi", namaAgensi)
        formdata.append("namaSingkatanAgensi", namaSingkatanAgensi)
        formdata.append("alamatAgensi", alamatAgensi)
        formdata.append("nomorTeleponAgensi", nomorTeleponAgensi)
        formdata.append("updatedBy", userId)


        await axios.post(url_api + "edit-agensi", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_EDIT_AGENSI', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async detailAgensi({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "detail-agensi",
          {
            params: {
              id: id,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DETAIL_AGENSI', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async setStatusAktifAgensi({ commit }, payload) {
      const { id, statusAktif } = payload
      try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("statusAktif", statusAktif)

        await axios.post(url_api + "set-status-aktif-agensi", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_STATUS_AKTIF_AGENSI', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async listPICAgensi({ commit }, payload) {
      const { isSearch, search, idAgensi, idRoleLink, index, limit } = payload

      try {
        await axios.get(url_api + "list-pic-agensi",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idAgensi: idAgensi,
              idRoleLink: idRoleLink,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_PIC_AGENSI', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async addPICAgensi({ commit }, payload) {
      const { idAgensi, email } = payload
      try {
        const formdata = new FormData()
        formdata.append("idAgensi", idAgensi)
        formdata.append("email", email)

        await axios.post(url_api + "add-pic-agensi", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_ADD_PIC_AGENSI', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async addPICAgensi2({ commit }, payload) {
      const { idAgensi, email } = payload
      try {
        const formdata = new FormData()
        formdata.append("idAgensi", idAgensi)
        formdata.append("email", email)

        await axios.post(url_api + "add-pic-agensi2", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_ADD_PIC_AGENSI2', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async deletePICAgensi({ commit }, payload) {
      const { idUser, idAgensi } = payload

      try {
        await axios.delete(url_api + "delete-pic-agensi",
          {
            params: {
              idUser: idUser,
              idAgensi: idAgensi,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DELETE_PIC_AGENSI', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async deletePICAgensi2({ commit }, payload) {
      const { idUser, idAgensi, email } = payload

      try {
        await axios.delete(url_api + "delete-pic-agensi2",
          {
            params: {
              idUser: idUser,
              idAgensi: idAgensi,
              email: email
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DELETE_PIC_AGENSI2', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async listPICAgensi2({ commit }, payload) {
      const { isSearch, search, idAgensi, idRoleLink, idRoleLink2, index, limit } = payload

      try {
        await axios.get(url_api + "list-pic-agensi2",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idAgensi: idAgensi,
              idRoleLink: idRoleLink,
              idRoleLink2: idRoleLink2,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_PIC_AGENSI2', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    //SUB KOMUNITAS
    async listSubKomunitas({ commit }, payload) {
      const { index, limit } = payload
      try {
        const userDataString = sessionStorage.getItem('userData');
        const userData = userDataString ? JSON.parse(userDataString) : null;
        const userId = userData?.id ?? null;

        await axios.get(url_api + "sub-komunitas",
          {
            params: {
              idUser: userId,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_SUB_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async listSub2Komunitas({ commit }, payload) {
      const { isSearch, search, idAgensi, index, limit } = payload
      try {
        await axios.get(url_api + "list-sub-komunitas",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idAgensi: idAgensi,
              index: index,
              limit: limit,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_SUB2_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async detailSubKomunitas({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "detail-sub-komunitas",
          {
            params: {
              id: id,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DETAIL_SUB_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async editGambarSubKomunitas({ commit }, payload) {
      const { id, gambarSubKomunitas } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("gambarSubKomunitas", gambarSubKomunitas)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-gambar-sub-komunitas", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_GAMBAR_SUB_KOMUNITAS', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async createSubCommunity({ commit }, payload) {
      const { namaSubKomunitas, deskripsiSubKomunitas, gambarSubKomunitas, idAgensi } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("namaSubKomunitas", namaSubKomunitas)
        formdata.append("deskripsiSubKomunitas", deskripsiSubKomunitas)
        formdata.append("gambarSubKomunitas", gambarSubKomunitas)
        formdata.append("idAgensi", idAgensi)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-sub-komunitas", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_SUB_KOMUNITAS', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editSubCommunity({ commit }, payload) {
      const { id, namaSubKomunitas, deskripsiSubKomunitas } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("namaSubKomunitas", namaSubKomunitas)
        formdata.append("deskripsiSubKomunitas", deskripsiSubKomunitas)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-sub-komunitas", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_EDIT_SUB_KOMUNITAS', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async setStatusAktifSubKomunitas({ commit }, payload) {
      const { id, statusAktif } = payload
      try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("statusAktif", statusAktif)

        await axios.post(url_api + "set-status-aktif-sub-komunitas", formdata,
          {
            headers: {

            },
          }
        ).then((response) => {
          commit('SET_STATUS_AKTIF_SUB_KOMUNITAS', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async listMemberSubKomunitas({ commit }, payload) {
      const { isSearch, search, idSubKomunitas, index, limit } = payload

      try {
        await axios.get(url_api + "list-member-sub-komunitas",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idSubKomunitas: idSubKomunitas,
              index: index,
              limit: limit,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_MEMBER_SUB_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async addMemberSubKomunitas({ commit }, payload) {
      const { idSubCommunity, email } = payload
      try {
        const formdata = new FormData()
        formdata.append("idSubKomunitas", idSubCommunity)
        formdata.append("email", email)

        await axios.post(url_api + "add-member-sub-komunitas", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_ADD_MEMBER_SUB_KOMUNITAS', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async deleteMemberSubKomunitas({ commit }, payload) {
      const { idSubCommunity, idUser } = payload

      try {
        await axios.delete(url_api + "delete-member-sub-komunitas",
          {
            params: {
              idSubKomunitas: idSubCommunity,
              idUser: idUser,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DELETE_MEMBER_SUB_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async setStatusConfirmMemberSubKomunitas({ commit }, payload) {
      const { idSubCommunity, idUser } = payload
      try {
        const formdata = new FormData()
        formdata.append("idSubKomunitas", idSubCommunity)
        formdata.append("idUser", idUser)

        await axios.post(url_api + "set-status-confirm-member-sub-komunitas", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_STATUS_CONFIRM_MEMBER_SUB_KOMUNITAS', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async changeRoleMemberSubKomunitas({ commit }, payload) {
      const { idSubCommunity, idUser, pic } = payload
      try {
        const formdata = new FormData()
        formdata.append("idSubKomunitas", idSubCommunity)
        formdata.append("idUser", idUser)
        formdata.append("pic", pic)

        await axios.post(url_api + "change-role-member-sub-komunitas", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CHANGE_ROLE_MEMBER_SUB_KOMUNITAS', response.data)
          })

      } catch (error) {
        return error
      }
    },
    //ARTICLE
    async listArticle({ commit }, payload) {
      const { isSearch, search, language, idAgensi, publish, index, limit } = payload

      try {
        await axios.get(url_api + "list-artikel",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idAgensi: idAgensi,
              language: language,
              publish: publish,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_ARTICLE', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async setPublishArticle({ commit }, payload) {
      const { id, publish } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("publish", publish)
        formdata.append("deletedBy", userId)

        await axios.post(url_api + "set-publish-artikel", formdata,
          {
            headers: {

            },
          }
        ).then((response) => {
          commit('SET_PUBLISH_ARTICLE', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async editGambarArticle({ commit }, payload) {
      const { id, gambarArtikel } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("gambarArtikel", gambarArtikel)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-gambar-artikel", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_GAMBAR_ARTICLE', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async createArticle({ commit }, payload) {
      const { judulArtikel, deskripsiArtikel, gambarArtikel, authorArtikel, jadwalArtikel, idAgensi, jenisContent, language, topic, tag } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("judulArtikel", judulArtikel)
        formdata.append("deskripsiArtikel", deskripsiArtikel)
        formdata.append("gambarArtikel", gambarArtikel)
        formdata.append("authorArtikel", authorArtikel)
        formdata.append("jadwalArtikel", jadwalArtikel)
        formdata.append("idAgensi", idAgensi)
        formdata.append("jenisContent", jenisContent)
        formdata.append("language", language)
        formdata.append("createdBy", userId)
        formdata.append("topik", topic)
        formdata.append("tag", tag)

        await axios.post(url_api + "create-artikel", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_ARTICLE', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async detailArticle({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "detail-artikel",
          {
            params: {
              id: id,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DETAIL_ARTICLE', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async editArticle({ commit }, payload) {
      const { id, judulArtikel, deskripsiArtikel, authorArtikel, jadwalArtikel, jenisContent, language, topic, tag } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("judulArtikel", judulArtikel)
        formdata.append("deskripsiArtikel", deskripsiArtikel)
        formdata.append("authorArtikel", authorArtikel)
        formdata.append("jadwalArtikel", jadwalArtikel)
        formdata.append("jenisContent", jenisContent)
        formdata.append("language", language)
        formdata.append("updatedBy", userId)
        formdata.append("topik", topic)
        formdata.append("tag", tag)

        await axios.post(url_api + "edit-artikel", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_EDIT_ARTICLE', response.data)
          })

      } catch (error) {
        return error
      }
    },
    //NEW VIDEO
    async listPlaylistVideo({ commit }, payload) {
      const { isSearch, search, publish, index, limit } = payload

      try {
        await axios.get(url_api + "list-playlist",
          {
            params: {
              isSearch: isSearch,
              search: search,
              publish: publish,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_PLAYLIST_VIDEO', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async createPlaylistVideo({ commit }, payload) {
      const { playlist } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("playlist", playlist)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-playlist", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          },
        })
          .then((response) => {
            commit('SET_CREATE_PLAYLIST_VIDEO', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editPlaylistVideo({ commit }, payload) {
      const { id, playlist } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("playlist", playlist)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-playlist", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          },
        })
          .then((response) => {
            commit('SET_EDIT_PLAYLIST_VIDEO', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async setPublishPlaylistVideo({ commit }, payload) {
      const { id, publish } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("publish", publish)
        formdata.append("deletedBy", userId)

        await axios.post(url_api + "set-publish-playlist", formdata,
          {
            headers: {

            },
          }
        ).then((response) => {
          commit('SET_PUBLISH_PLAYLIST_VIDEO', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async listNewVideo({ commit }, payload) {
      const { isSearch, search, idPlaylist, index, limit } = payload

      try {
        await axios.get(url_api + "list-new-video",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idPlaylist: idPlaylist,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_NEW_VIDEO', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async editNewVideo({ commit }, payload) {
      const { id, judul, video, jadwal } = payload
      try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("judul", judul)
        formdata.append("video", video)
        formdata.append("jadwal", jadwal)

        await axios.post(url_api + "edit-new-video", formdata,
          {
            headers: {

            },
          }
        ).then((response) => {
          commit('SET_EDIT_NEW_VIDEO', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async createNewVideo({ commit }, payload) {
      const { idPlaylistVideo, judul, video, jadwal } = payload
      try {
        const formdata = new FormData()
        formdata.append("idPlaylistVideo", idPlaylistVideo)
        formdata.append("judul", judul)
        formdata.append("video", video)
        formdata.append("jadwal", jadwal)

        await axios.post(url_api + "create-new-video", formdata,
          {
            headers: {

            },
          }
        ).then((response) => {
          commit('SET_CREATE_NEW_VIDEO', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async deleteNewVideo({ commit }, payload) {
      const { id } = payload

      try {
        await axios.delete(url_api + "delete-new-video",
          {
            params: {
              id: id,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DELETE_NEW_VIDEO', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    //AGENDA
    async copyAgenda({ commit }, payload) {
      const { id, idSubKomunitas } = payload
      try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("idSubKomunitas", idSubKomunitas)

        await axios.post(url_api + "copy-agenda", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          },
        })
          .then((response) => {
            commit('SET_COPY_AGENDA', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async listAgendaCopySubKomunitas({ commit }, payload) {
      const { isSearch, search, language, index, limit } = payload

      try {
        await axios.get(url_api + "list-agenda-copy",
          {
            params: {
              isSearch: isSearch,
              search: search,
              language: language,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_AGENDA_COPY_SUB_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async listAgenda({ commit }, payload) {
      const { isSearch, search, idAgensi, language, index, limit } = payload

      try {
        await axios.get(url_api + "list-agenda",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idAgensi: idAgensi,
              language: language,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_AGENDA', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async listAgendaSubKomunitas({ commit }, payload) {
      const { isSearch, search, idSubKomunitas, language, index, limit } = payload

      try {
        await axios.get(url_api + "list-agenda-sub-komunitas",
          {
            params: {
              isSearch: isSearch,
              search: search,
              idSubKomunitas: idSubKomunitas,
              language: language,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_AGENDA_SUB_KOMUNITAS', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async createAgenda({ commit }, payload) {
      const { language, judulAgenda, deskripsiAgenda, jadwalAgenda, topik, tag, gambarAgenda, idAgensi } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("language", language)
        formdata.append("judulAgenda", judulAgenda)
        formdata.append("deskripsiAgenda", deskripsiAgenda)
        formdata.append("jadwalAgenda", jadwalAgenda)
        formdata.append("topik", topik)
        formdata.append("tag", tag)
        formdata.append("gambarAgenda", gambarAgenda)
        formdata.append("idAgensi", idAgensi)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-agenda", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_AGENDA', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async createAgendaSubKomunitas({ commit }, payload) {
      const { language, judulAgenda, deskripsiAgenda, jadwalAgenda, topik, tag, gambarAgenda, idSubKomunitas } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("language", language)
        formdata.append("judulAgenda", judulAgenda)
        formdata.append("deskripsiAgenda", deskripsiAgenda)
        formdata.append("jadwalAgenda", jadwalAgenda)
        formdata.append("topik", topik)
        formdata.append("tag", tag)
        formdata.append("gambarAgenda", gambarAgenda)
        formdata.append("idSubKomunitas", idSubKomunitas)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-agenda-sub-komunitas", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_AGENDA_SUB_KOMUNITAS', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async createDetailAgenda({ commit }, payload) {
      const { form } = payload
      try {
        await axios.post(url_api + "create-detail-agenda", form, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            commit('SET_CREATE_DETAIL_AGENDA', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editGambarAgenda({ commit }, payload) {
      const { id, gambarAgenda } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("gambarAgenda", gambarAgenda)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-gambar-agenda", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_GAMBAR_AGENDA', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async detailAgenda({ commit }, payload) {
      const { id, type } = payload
      try {
        await axios.get(url_api + "detail-agenda", {
          params: {
            id: id,
            type: type,
          },
          headers: {
            "Content-Type": "application/json"
          }
        }).then((response) => {
          commit('SET_DETAIL_AGENDA', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async editDetailAgenda({ commit }, payload) {
      const { idAgenda, idDetailAgenda, deskripsi } = payload
      try {
        const formdata = new FormData()
        formdata.append("idAgenda", idAgenda)
        formdata.append("idDetailAgenda", idDetailAgenda)
        formdata.append("deskripsi", deskripsi)

        await axios.post(url_api + "edit-detail-agenda", formdata,
          {
            headers: {

            },
          }
        ).then((response) => {
          commit('SET_EDIT_DETAIL_AGENDA', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async deleteAgenda({ commit }, payload) {
      const { id } = payload
      try {
        await axios.delete(url_api + "delete-agenda", {
          params: {
            id: id,
          },
          headers: {

          }
        }).then((response) => {
          commit('SET_DELETE_AGENDA', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async deleteAgendaCopy({ commit }, payload) {
      const { id, idKomunitas } = payload
      try {
        await axios.delete(url_api + "delete-copy-agenda", {
          params: {
            id: id,
            idKomunitas: idKomunitas,
          },
          headers: {

          }
        }).then((response) => {
          commit('SET_DELETE_AGENDA_COPY', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async detailMasterAgenda({ commit }, payload) {
      const { id } = payload
      try {
        await axios.get(url_api + "detail-master-agenda", {
          params: {
            id: id,
          },
          headers: {

          }
        }).then((response) => {
          commit('SET_DETAIL_MASTER_AGENDA', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async editMasterAgenda({ commit }, payload) {
      const { id, language, judulAgenda, deskripsiAgenda, jadwalAgenda, topik, tag } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("language", language)
        formdata.append("judulAgenda", judulAgenda)
        formdata.append("deskripsiAgenda", deskripsiAgenda)
        formdata.append("jadwalAgenda", jadwalAgenda)
        formdata.append("topik", topik)
        formdata.append("tag", tag)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-master-agenda", formdata, {
          headers: {

          },
        })
          .then((response) => {
            commit('SET_EDIT_MASTER_AGENDA', response.data)
          })

      } catch (error) {
        return error
      }
    },
    //PRODUK
    async createProduk({ commit }, payload) {
      const { isPromo, judulProduk, startDate, endDate, price, endPrice, promotionPrice, deskripsiProduk, spesifikasiProduk, bannerProduk } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("isPromo", isPromo)
        formdata.append("judulProduk", judulProduk)
        formdata.append("startDate", startDate)
        formdata.append("endDate", endDate)
        formdata.append("price", price)
        formdata.append("endPrice", endPrice)
        formdata.append("promotionPrice", promotionPrice)
        formdata.append("deskripsiProduk", deskripsiProduk)
        formdata.append("spesifikasiProduk", spesifikasiProduk)
        formdata.append("bannerProduk", bannerProduk)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-produk", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_PRODUK', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async listProduk({ commit }, payload) {
      const { isSearch, search, index, limit } = payload

      try {
        await axios.get(url_api + "list-produk",
          {
            params: {
              isSearch: isSearch,
              search: search,
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_PRODUK', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async addThumbnailProduk({ commit }, payload) {
      const { id, thumbnailProduk } = payload
      try {

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("thumbnailProduk", thumbnailProduk)

        await axios.post(url_api + "add-thumbnail-produk", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_ADD_THUMBNAIL_PRODUK', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async listThumbnailProduk({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "list-thumbnail-produk",
          {
            params: {
              id: id
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_THUMBNAIL_PRODUK', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async deleteThumbnailProduk({ commit }, payload) {
      const { id, thumbnail } = payload

      try {
        await axios.delete(url_api + "delete-thumbnail-produk",
          {
            params: {
              id: id,
              thumbnail: thumbnail
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`,
              "Content-Type": "multipart/form-data",
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_THUMBNAIL_PRODUK', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async setStatusPublishProduk({ commit }, payload) {
      const { id, publish } = payload
      try {

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("publish", publish)

        await axios.post(url_api + "set-publish-status-produk", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
          .then((response) => {
            commit('SET_STATUS_PUBLISH_PRODUK', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editProduk({ commit }, payload) {
      const { id, isPromo, judulProduk, startDate, endDate, price, endPrice, promotionPrice, deskripsiProduk, spesifikasiProduk } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("isPromo", isPromo)
        formdata.append("judulProduk", judulProduk)
        formdata.append("startDate", startDate)
        formdata.append("endDate", endDate)
        formdata.append("price", price)
        formdata.append("endPrice", endPrice)
        formdata.append("promotionPrice", promotionPrice)
        formdata.append("deskripsiProduk", deskripsiProduk)
        formdata.append("spesifikasiProduk", spesifikasiProduk)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-produk", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`,
          },
        })
          .then((response) => {
            commit('SET_EDIT_PRODUK', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async detailProduk({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "detail-produk",
          {
            params: {
              id: id
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`,
            }
          }
        )
          .then((response) => {
            commit('SET_DETAIL_PRODUK', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async editBannerProduk({ commit }, payload) {
      const { id, bannerProduk } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("bannerProduk", bannerProduk)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-banner-produk", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_BANNER_PRODUK', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async editBannerMobileProduk({ commit }, payload) {
      const { id, bannerMobileProduk } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("bannerMobileProduk", bannerMobileProduk)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-banner-mobile-produk", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_BANNER_MOBILE_PRODUK', response.data)
        })
      } catch (error) {
        return error
      }
    },
    //SLIDER
    async listSlider({ commit }, payload) {
      const { index, limit } = payload

      try {
        await axios.get(url_api + "list-slider",
          {
            params: {
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_SLIDER', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async createSlider({ commit }, payload) {
      const { judulSlider, startDate, endDate, url, defaultSlider, mobileSlider } = payload
      try {
        const formdata = new FormData()
        formdata.append("judulSlider", judulSlider)
        formdata.append("startDate", startDate)
        formdata.append("endDate", endDate)
        formdata.append("url", url)
        formdata.append("defaultSlider", defaultSlider)
        formdata.append("mobileSlider", mobileSlider)

        await axios.post(url_api + "create-slider", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_SLIDER', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editSlider({ commit }, payload) {
      const { id, judulSlider, startDate, endDate, url } = payload
      try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("judulSlider", judulSlider)
        formdata.append("startDate", startDate)
        formdata.append("endDate", endDate)
        formdata.append("url", url)

        await axios.post(url_api + "edit-slider", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          },
        })
          .then((response) => {
            commit('SET_EDIT_SLIDER', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async editDefaultSlider({ commit }, payload) {
      const { id, defaultSlider } = payload
      try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("defaultSlider", defaultSlider)

        await axios.post(url_api + "edit-default-slider", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_DEFAULT_SLIDER', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async editMobileSlider({ commit }, payload) {
      const { id, mobileSlider } = payload
      try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("mobileSlider", mobileSlider)

        await axios.post(url_api + "edit-mobile-slider", formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response) => {
          commit('SET_EDIT_MOBILE_SLIDER', response.data)
        })
      } catch (error) {
        return error
      }
    },
    async deleteSlider({ commit }, payload) {
      const { id } = payload
      try {
        await axios.delete(url_api + "delete-slider", {
          params: {
            id: id,
          },
          headers: {

          }
        }).then((response) => {
          commit('SET_DELETE_SLIDER', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async moveSlider({ commit }, payload) {
      const { idSlider, newPosition } = payload
      try {
        const formdata = new FormData()
        formdata.append("idSlider", idSlider)
        formdata.append("newPosition", newPosition)

        await axios.post(url_api + "move-slider", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          },
        })
          .then((response) => {
            commit('SET_MOVE_SLIDER', response.data)
          })

      } catch (error) {
        return error
      }
    },
    //ACL
    async listACL({ commit }, payload) {
      const { email } = payload

      try {
        await axios.get(url_api + "list-acl",
          {
            params: {
              email: email
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_ACL', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async changeStatusACL({ commit }, payload) {
      const { email, menuId, status } = payload
      try {
        const formdata = new FormData()
        formdata.append("email", email)
        formdata.append("menuId", menuId)
        formdata.append("status", status)

        await axios.post(url_api + "change-status-acl", formdata,
          {
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        ).then((response) => {
          commit('SET_CHANGE_STATUS_ACL', response.data)
        })
      } catch (error) {
        return error
      }
    },
    //BROADCAST
    async createBroadcast({ commit }, payload) {
      const { idKomunitas, text } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const username = userData?.username ?? null

        const formdata = new FormData()
        formdata.append("idKomunitas", idKomunitas)
        formdata.append("text", text)
        formdata.append("username", username)

        await axios.post(url_api + "create-broadcast", formdata,
          {
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        ).then((response) => {
          commit('SET_CREATE_BROADCAST', response.data)
        })
      } catch (error) {
        return error
      }
    },
    //UNDUHAN
    async listUnduhan({ commit }, payload) {
      const { index, limit } = payload

      try {
        await axios.get(url_api + "list-unduhan",
          {
            params: {
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_UNDUHAN', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async createUnduhan({ commit }, payload) {
      const { judulUnduhan, thumbnail, unduhan } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("judulUnduhan", judulUnduhan)
        formdata.append("thumbnail", thumbnail)
        formdata.append("unduhan", unduhan)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-unduhan", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            commit('SET_CREATE_UNDUHAN', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async deleteUnduhan({ commit }, payload) {
      const { id } = payload
      try {
        await axios.delete(url_api + "delete-unduhan", {
          params: {
            id: id,
          },
          headers: {

          }
        }).then((response) => {
          commit('SET_DELETE_UNDUHAN', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async editUnduhan({ commit }, payload) {
      const { id, judulUnduhan } = payload
      try {
        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("judulUnduhan", judulUnduhan)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "edit-unduhan", formdata,
          {
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        ).then((response) => {
          commit('SET_EDIT_UNDUHAN', response.data)
        })
      } catch (error) {
        return error
      }
    },
    //EDITOR TEXT
    async editorText({ commit }) {
      try {
        await axios.get(url_api + "editor-text",
          {
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_EDITOR_TEXT', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    //SEMINAR
    async listSeminar({ commit }, payload) {
      const { index, limit } = payload

      try {
        await axios.get(url_api + "list-seminar-kursus",
          {
            params: {
              index: index,
              limit: limit
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_LIST_SEMINAR', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async createSeminar({ commit }, payload) {
      const { tanggalMulai, jadwalKegiatan, kategori, lokasi, topik } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("tanggalMulai", tanggalMulai)
        formdata.append("jadwalKegiatan", jadwalKegiatan)
        formdata.append("kategori", kategori)
        formdata.append("lokasi", lokasi)
        formdata.append("topik", topik)
        formdata.append("createdBy", userId)

        await axios.post(url_api + "create-seminar-kursus", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          },
        })
          .then((response) => {
            commit('SET_CREATE_SEMINAR', response.data)
          })

      } catch (error) {
        return error
      }
    },
    async deleteSeminar({ commit }, payload) {
      const { id } = payload
      try {
        await axios.delete(url_api + "delete-seminar-kursus", {
          params: {
            id: id,
          },
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          }
        }).then((response) => {
          commit('SET_DELETE_SEMINAR', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
    async detailSeminar({ commit }, payload) {
      const { id } = payload

      try {
        await axios.get(url_api + "detail-seminar-kursus",
          {
            params: {
              id: id,
            },
            headers: {
              "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
          }
        )
          .then((response) => {
            commit('SET_DETAIL_SEMINAR', response.data.data)
          })
      } catch (error) {
        return error
      }
    },
    async editSeminar({ commit }, payload) {
      const { id, tanggalMulai, jadwalKegiatan, kategori, lokasi, topik } = payload
      try {

        const userDataString = sessionStorage.getItem('userData')
        const userData = userDataString ? JSON.parse(userDataString) : null
        const userId = userData?.id ?? null

        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("tanggalMulai", tanggalMulai)
        formdata.append("jadwalKegiatan", jadwalKegiatan)
        formdata.append("kategori", kategori)
        formdata.append("lokasi", lokasi)
        formdata.append("topik", topik)
        formdata.append("updatedBy", userId)

        await axios.post(url_api + "update-seminar-kursus", formdata, {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
          },
        })
          .then((response) => {
            commit('SET_EDIT_SEMINAR', response.data)
          })

      } catch (error) {
        return error
      }
    },
    //USERS
    async listUsers({ commit }, payload) {
      const { index, limit } = payload
      try {
        await axios.get(url_api + "/list-user", {
          params: {
            index: index,
            limit: limit
          },
          headers: {
            "Content-Type": "application/json"
          }
        }).then((response) => {
          commit('SET_LIST_USERS', response.data.data)
        })
      } catch (error) {
        return error
      }
    },
  },
  modules: {
    donasi,
  }
})
